

*, *:before, *:after, input[type="search"] { box-sizing: border-box }
.taxrates{
  thead th{
    text-align: left;
  }
}
.td.contactinfo{
  white-space: pre-wrap;
 
}
.form-link a{
  text-decoration: underline;
}
.checkki .checbox,.checkki label{
float:left;

}
.pre_links a{
  padding-right:.2em;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.noedit{
 
  label{
    display: block;
    width:100%;
    
  }
}
.checkki .input{
  display: block;
  clear: both;
  
}
.form-block.full{
  width:100%;
  display: block;
  white-space: pre-wrap;
}
.td.pdf a{
  text-decoration: underline;
}
span.move-item{
cursor: pointer;
}
.memo-text textarea{

  width:100%;
  border:1px solid #f3f5f7;
    background-color:#f3f5f7;
    font-size:1em;
    padding:.4em .4em;
    outline: none;
    width:100%;
 

    height:80px;
  
  &:focus{
    outline: none;
    border:1px solid #e4328a!important;
  }
}
td.urlini{
  min-width: 100px;
}
td.s-select{
  select{
    max-width:150px;
  }
}
.bgtable{
  background-color:white;
}
.loginContainer{

  display: flex;
  align-items: center;
  justify-content: center;
  .loginForm{
    background-color:white;
    padding:1em;
    margin-top:2em;
    form{
    display: flex;
    flex-direction: column;
    min-width: 300px;
    .label-input,.label-password{
      padding-bottom:1em;
      input{
        display: block;
        width:100%;

      }
    
        text-transform: uppercase;
      
      input{
          
        border:1px solid #f3f5f7;
        background-color:#f3f5f7;
        font-size:1em;
        padding:.4em .4em;
        outline: none;
      }
      input:focus{
        outline: none;
        border:1px solid black;
      }
      
      
    }
    input[type="submit"]{
      background-color: lightgray;
      border:none;
      outline:none;
      color:black;
      text-transform: uppercase;
      padding:.5em 0em;
      cursor: pointer;

    }
    }
  }
}

.sidenav span{
width:100%;
display:block;
text-transform: uppercase;

  text-align: center;
  font-size:.8em;
  padding-top:.5em;
  &:hover{
    color:black;
    cursor: pointer;
  }
}

.smaller .td-inner{
  font-size:.9em;
  white-space: pre-wrap;
}
div.errors{
  background-color: red;
  padding:1em;
  .error{
    color:white;
    padding:.5em 1em;
    text-transform: uppercase;
  }
}
li.ver{
  text-align: center;
  padding-top:1em;
  color:#303030!important;
  font-size:.8em;
  text-transform: uppercase;

}
.dates{
  span{
    display: block;
  }
}
h2{
  color: #e4328a;
  text-transform: uppercase;
  font-weight: bold;
}
.input-clock{
display: flex;
}

.event-dates{
  display: block!important;
  &.edit{
    span:after{
      content:"remove";
    }
  }
    span{
      display: block;
    }
}
.sponsor-img img{
  max-width: 100px;
  width:100%;
  height:auto;
}
.fringetable{
width:100%;

&.has-links{
  tr:hover{
    a{
      color: #e4328a!important;
    }
  }
}
  th{
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom:.5em!important;
  }
  td{
    padding-left:0!important;
    padding-bottom:.5em!important;
  }
}
.tr .input {
  width:100%;
  margin-bottom:0!important;
  input,textarea{
          
    border:1px solid #f3f5f7;
    background-color:#f3f5f7;
    font-size:1em;
    padding:.4em .4em;
    outline: none;
    width:100%;
  }
  textarea{
    height:150px;
  }
  input:focus,textarea:focus{
    outline: none;
    border:1px solid #e4328a!important;
  }
}

h2{
  display: inline-block;
}
.edit-btn {
  display: inline-block;
  margin-left:1em;
}
.table-div{

  display: block;         
  width: auto;         
         
       
  border-spacing: 5px; /* cellspacing:poor IE support for  this */

.tr {
  display: flex;
 
 margin-bottom:.5em;

}
.td{
 display: flex;
 align-items: start;
 font-size:.9em;

 .td-inner{
   margin-right:1em;
   width:100%;
 }
 &.th{
  width:190px!important;
}
  &.th,&.td-th{
    font-weight: bold;
    font-size:0.9em;
    text-transform: uppercase;
  }
  width: 300px;  

  &.td-check{
    width:30px;
  }
  &.td-locale{
    font-weight: bold;
    text-transform: uppercase;
  }    
  &.remove-me{
    font-size:.9em;
    text-transform: uppercase;
    &:hover{
      color:red;
      cursor: pointer;
    }
  }   
  
}

}
.fringetable{
 
    tr{
      td{
        padding-top:1em!important;
        border-bottom:1px solid lightblue;
      }
    }
  
}
.uploader{
  span{
    padding: 0.5em 1em;
    background-color: #5c6779;
    font-size: 0.7em;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
  }
}
span.remove-me,span.remove{
    text-transform: uppercase;
    padding:.5em;
    &:hover{
      color:red;
      cursor: pointer;
    }
}
.show-image{
  width:200px;
 
  height:Auto;
}
h2.color-black{
  color:black;
  text-transform: none;
}
.leafnav-small{
  margin-bottom:.5em;
  ul{
    padding:0;
   
    list-style: none;
    li{
      margin-right: 1em;
      &.active-li{
        a{
          background-color: #e4328a;
          color:white;

        }
      }
      a{
        font-size:0.9em;
        text-transform: uppercase;
        padding:.20em 1em;
        border-radius: 5px;
        background-color:lightgray;
        
      }
      display: inline-block;
    }
  }
}
.uploader{
  input{
    display: none;
  }
}
.wide{
  flex-basis: 100%;
  width:100%;
  display: flex;
}
.translations{
  width:100%;
  .fields >div{
    display: flex;
    .trans{
      flex-basis: 50%;
    
    }
  }
 
}
.table-new{

  width:100%;
  thead{
    th{
      text-align: left;
    }
  }
}
@media print {
@page {
  size: auto;
  margin: 0;
  }

  .App > .left {
   display: none!important;
  }
  .App > .right{

    position: static!important;
    left:0!important;
    right:0!important;
    .palkka-nav{
      display: none;
    }
  }

  
}

.settings-all{
.setting-section{
  h5{
    line-height: 1.5em;
    padding-left:.5em;
  }
}
  table{
    font-size:.9em;
    th,td{
      padding-right:.6em;
    }
  }
  .btn-remove{
    cursor: pointer;
    &:hover{
      color:red;
    }
  }
}

.results-preview{
  margin-bottom:2em;
  .wage-display-holder{
    padding:0;
    font-size:.9em;
    table{
      margin-left:0;
      margin-right:0;
    }
   
  }
}
.wage-display-holder{
.buttons{
  text-align: center;
  
  margin:2em;
  button{
   border:none;
   outline:none;
    cursor: pointer;
  
    padding: 1em 1em;
    background-color: #5c6779;
    font-size: .8em;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
    &:hover{
      background-color:black;

    }
  }
}
}
.provision-editor,.yrityslainat-editor{
  padding-top:1em;
  width:100%;
}
.smalltable{
  padding-top:.5em;
  padding-bottom:.5em;
table{
  font-size:.8em;
  width:100%;
  th{
    text-align: left;
   
  }
}

}
.palkka-nav{
  padding-left:0;
li{
  display: inline-block;
  margin-right:1em;
  span{
    cursor: pointer;
    padding: 0.5em 1em;
    background-color: #5c6779;
    font-size: 0.7em;
    color: white;
    border-radius: 5px;
    text-transform: uppercase;
   
  }
  &.active span{
    color: lightgray;
  box-shadow: inset 0 0 10px #000000;
  }
}
 
}

.wage-display-holder{


  .yhteenveto-all{
    td{
      text-align: left;

    }
    th{
      padding-right:1em;
    }
  }
  max-width:900px;
  margin:0 auto;
  background-color:white; 
  padding:1em;
  
  table{
    margin:1em;
  }
}
.error-wage{
  color:red;
  font-weight: bold;
}
.input-procent{
padding-right:0!important;

input{
  display:inline-block!important;
  width:calc(100% - 30px)!important;
}
  &:after{
    content:" %";
    display:inline;
  }
}
table.palkkalaskelma{
  font-size:.9em;
  width:100%;
 
  .border{
    border-top:1px solid black;
    padding-top:1em;
  }
  td.strong{
    font-weight: bold;
  }
  .address_rows{
    span{
      font-weight: bold;
      display: block;
    }
  }
  .info_rows{
    span{
   
      display: block;
    }
  }
  thead tr th{
  
  }
  tr th{
    text-align: left;
    
  }
  tr.header-row-lisatietoja th{
    padding-top:2em;
    border-bottom:1px solid black;
  }
}
body{
  background-color: #f3f5f7!important;
 min-height: 100%;
 height: 100%;
}
.sheet{
  table{
    width:100%;
    tr.item{
      td{
        font-size: .8em;
      }
      td.value{
        text-align: right;
      }
    }
    tr.total{

      td{
        text-align: right;
        font-weight:bold;
        font-size: .8em;
      }

    }
  }
}
.previouswages{

.paywage{
background-color: #f2f2f0;
margin-top: 0!important;
border-bottom:5px solid lightgray;
}

}
.preview .table{
  tbody tr td{

   
      padding-top:.3em;
   
  }
  tbody tr:last-child{
    font-weight: bold;

   
   td{
    padding-top:1em;
    &.amount{
      text-decoration: underline;
    }
   }
  }
}
.wage-content{
  .left{
    h4{
      margin:.6em 0em;
      user-select: none;
    }
    
  }
  .right{
    h3{
      font-size:1em;
      
    }
    h4{
      margin:.6em 0em;
      user-select: none;
    }
    .table{
      width:100%;
      font-size:.9em;
      .formula{
        font-size:.8em;
        display: none;
      }
      th{
        text-align: left;
      }
    }

  }
}
.form-blocks{
  background-color: white;
  padding:1em;

  .form-block{

      .input{
        &.input-edit-false{
          margin-bottom:0;
          padding-right: 0em;
        }
        &.error{
          input{
            background-color:red;
            color:white;
          }
          .error-text{
            font-size:0.8em;
            color:red;
          }
        }
        label{
          font-size:.9em;
          padding-bottom:.2em;
        }
        input{
          
          border:1px solid #f3f5f7;
          background-color:#f3f5f7;
          font-size:1em;
          padding:.4em .4em;
          outline: none;
        }
        input:focus{
          outline: none;
          border:1px solid black;
        }
        
      }
  }
}

.medic{
  input:focus{
    outline: none;
    border:1px solid #3ead4b!important;
  }
  .leafnav{
    ul{
    border-bottom:3px solid#3ead4b;

    .active-li{
      background-color:#3ead4b;
      color:white;
      a{
        color:white;
      }
    }
    }
  }
}
.safe{
  input:focus{
    outline: none;
    border:1px solid red !important;
  }
 .leafnav{
  ul{
    border-bottom:3px solid red;
    .active-li{
      background-color: red;
      color:white;
      a{
        color:white;
      }
    }

    


  }
  }
}
.hidden{
  display: none;
}
.wage-content{
.both{
  display: flex;
  background-color: white;
 .left{
   flex-basis: 50%;
 }
 .right{
  flex-basis: 50%;
 }
}
}
.form-blocks{

  .row{
    display: flex;
    flex-basis:100%;
   
   .col{
    display: flex;
    flex-direction: column;
      justify-content: flex-end;

     .input{
    
      
      label{
       
      }
     
     }
   }
  }
  h4{display: block;width:100%;}
}

.leafnav{
  &.as-event-menu{
    ul li a{
      font-size:.75em;
    }
    ul li.active-li{
     
    }
  }
    ul{
      margin:0;
      padding-left:0;
      >li{
        user-select: none;
        display: inline-block;
        padding:.5em 1em;
        margin-right:.2em;
        opacity:.5;
        background-color:white;
        &.nav{
          padding:0;
          ul li{
            padding:.5em 1em!important;
           
          }

        }
        &.active-li{

          opacity:1;
          background-color: #e4328a;
          a{
            color:white;
          }
          &.current-half{
            opacity:.5;

          }
        }
        a{
          line-height:1.2em;
         text-transform: uppercase;
         font-size:.9em;
         font-weight: bold;
        }
        &.nav{
          opacity: 1!important;
        }
        

    }
    border-bottom:3px solid  #e4328a;

    li.nav{
      ul{
        border-bottom: none;
        li{
          padding:0;
          cursor:pointer;
        }
      }
        

    }


}

}

.mycontent{
  padding-left:1em;
  padding-right:1em;
  .table-new{
   
    border-collapse: collapse; 
    width:100%;
      tbody{
       
       
        overflow:auto;
        tr.data-row{
          border-top:1px solid lightgray;
     
          background-color:white;
       
          td{
font-size:.9em;
line-height: 3em;
          }

        }
        tr.text-header + tr{
          border-top:none;
        }
        tr td{
          padding:0em .4em;
        }

        &.medic{

          tr.text-header{
            border-bottom:3px solid#3ead4b;
          }
        }
        &.safe{

          tr.text-header{
            border-bottom:3px solid red;
          }
        }

        tr.text-header{
        
         
         
         
          th{
            padding:0em .4em;
            text-align: left;
            font-size:.9em;
            line-height: 2em;
            color:gray;
            text-transform: uppercase;
            user-select: none;
            &.orderable{
              cursor: pointer;

              &.order-active{
                color:black;
                &:after{
                  content:"▼";
                  padding-left:.5em;
                  font-size:.5em;
                  display: inline-block;
                  transform: translateY(-1px);
                }
                &.desc{
                  &:after{
                    content:"▲";
                    transform: translateY(-2px);
                  }
                }
              }
            }

          }
        }
        tr.header  {
     
          th{
          padding-top:3em;
          padding-bottom:1em;
          text-align: left;

          h2{
            margin-top:0;
            margin-bottom:0;
            span{
              font-size:.5em;
              font-weight: normal;
              text-transform: uppercase;
              display: block;
              color:gray;
            }

          }
          }
        }
      }

      }

}
.topnav{
  display: none!important;
}

.forms {
  
  fieldset {
    padding: 1em 1em;
    margin: 1em 0;
  }
  
  legend {
    padding: 0;
    font-weight: bold;
    font-size: 1.2em;
  }
  
  label {
    display: block;
    margin: .5em 0;
    font-size:.8em;
   
   text-transform: uppercase;
    
    b {
      //font-weight: normal;
    }
    
    // can I use :not here?
    textarea, select, input[type="text"], input[type="email"], input[type="search"], input[type="tel"], input[type="url"], input[type="password"], input[type="color"], input[type="file"], input[type="color"], input[type="number"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"] {
      display: block;
      width: 100%; // good?
    }
  }
  
  textarea, select, input[type="text"], input[type="email"], input[type="search"], input[type="tel"], input[type="url"], input[type="password"], input[type="color"], input[type="file"], input[type="color"], input[type="number"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"] {
      margin: .2em 0;
      padding: .25em .4em;
      border: 1px solid #ddd;
      max-width: 100%; // ????
  }
  
  input[type="search"] { 
    box-sizing: border-box;
  }
  
  input[type="file"] { 
    border-width: 0;
    padding: 0;
  }
  
  
  input[type="image"] {
    vertical-align: bottom;
  }
  
}










.has-event{

display: flex;
align-items:center;
h3{
  color: #e4328a;
    text-transform: uppercase;
    font-weight: bold;
    font-size:24px;
    &::after{
      content:"/";
      color:black;
      padding-left:.25em;
      padding-right:.25em;

    }
}
h2{
  color:black;
  text-transform: none;
  font-size:20px;
}

}



body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color:white;
  }
h4.arrow{
cursor:pointer;
  &:before{

    content:"▸";
    padding-right:.2em;
  }
  &.open:before{
    content:"▼";
    font-size:.5em;
  }
}
.row.closed{
  display: none!important;
}
  .period-topic{
    h2{
      display: inline-block;
    }
    &>div{
      
      display: inline-block;
      margin-left:1em;
      .period{
        font-weight: bold;
        cursor:pointer;
        &:after{
          content:"▼";
          color: gray;
          font-size:.6em;
          padding-left:.5em;
        }
      }
    }
  }
.right > .content{

  margin-top:0px;
}
  .topnav{

    position:fixed;
    width:100%;
    overflow:auto;
    background-color:#3b4557;
    min-height: 60px;
    display: flex;
   top:0;
    justify-content: flex-start;
    .search{

       flex-grow: 1;
       display: flex;
       justify-content: flex-end;
       input{
         display: none;
       }
    }
        ul{

            display: flex;
            list-style: none;
            padding-left:2em;
            align-items: center;
           h2{
               margin:0;
               font-size:1.1em;
               text-transform: uppercase;
           }
           li{
             margin-right:.5em;
             a{
               text-transform: uppercase;
               font-size:.8em;
               padding:.5em .7em;
               &.active{
                 background-color:lightblue;
                 border-radius:1em;
                 color:black;
               }
               cursor: pointer;
              color:white;
             }
           }
           
        }

  }
  .settings-all{
    width:100%;
  }
  .setting-section{
    flex-basis: 100%;
    width:100%;
    h5{
      width:100%;
      text-transform: uppercase;
      font-size:1em;
      padding:.2em;
      background-color:lightgray;
    }
  }
  .creditcard{
  .nettoform{
    background-color:whitesmoke;
    padding:1em;
display:flex;
    .col{
      flex-basis:20%;
      padding:.5em;
      &.store{
        display: flex;
       
        flex-shrink: 0;
        align-items: center;
        justify-content: right;
        button{
          margin-left:1em;
        }
      }
label{
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size:.9em;
  margin-bottom:.3em;

}
    }



  }

}
table.lainan-lyhennykset{
  display::block;
  width:100%;
}
  .paywage{
    &.content .inners{
      margin-left:1em;
      margin-right:1em;
      display:flex;
      flex-basis: 100%;
    }
   &.content .inner{
      width:50%;
      margin:0;


      &.leftinner{
       
        .box{
        border-right:1px solid lightgray;
        border-left:1px solid lightgray;
        }
      }

      &.rightinner{
        .box{
          border-left:none!important;

          border-right:1px solid lightgray;
        }

      }
      .box{
       
      
      }
    }
    h3{
        margin-top:2em;
    }
    .row{
        display: flex;
        flex-wrap: wrap;
        margin-bottom:1em;
        flex-basis:100%;
        &.three{
          .col{
            flex-basis:33.33%;
          }
        }
        .col{
            flex-basis:50%;
            label{
                display: block;
            }
            &.col-wide{
                flex-basis:100%;
            }
        }
    }
  }
  .person-content{
      h2{
          display: inline-block;
          margin-right:1em;
          span{
              cursor: pointer;
              padding:.5em 1em;
              background-color:#5c6779;
              font-size:0.7em;
              color:white;
              border-radius: 5px;
              text-transform: uppercase;
          }
          &.active span{
              color:lightgray;
            -moz-box-shadow:    inset 0 0 10px #000000;
            -webkit-box-shadow: inset 0 0 10px #000000;
            box-shadow:         inset 0 0 10px #000000;
          }
      }
  }
  .remove-btn:hover{
    span{
      background-color: red;
     
    }
  }
  .save-btn:hover{
      span{
        background-color: green;
      }
  }

  .remove-btn{
margin-left:2em;
  }
 
  .edit-btn,.save-btn,.remove-btn{
    display: inline-block;
    margin-right:1em;
    span{
        cursor: pointer;
        padding:.5em 1em;
        background-color:#5c6779;
        font-size:0.7em;
        color:white;
        border-radius: 5px;
        text-transform: uppercase;
    }
    &.active span{
        color:lightgray;
      -moz-box-shadow:    inset 0 0 10px #000000;
      -webkit-box-shadow: inset 0 0 10px #000000;
      box-shadow:         inset 0 0 10px #000000;
    }
}



  .App{

    min-height: 100%;
    >.left{

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width:100px;
        height:100%;
        border-left:1px;
        background-color:#ffffff;
      min-height: 100%;
      bottom:0;
        h1{
            background-color:#e4328a;
            margin:0;
            padding:0;
            line-height:60px;
            color:white;
            text-align:center;
        }
        ul{
            list-style: none;
            padding-left:0em;
            padding-right:0em;  
            margin-top:0;
            li a{
                text-align: center;
                font-size:.8em;
                svg{
                    display: block;
                    margin:0 auto;
                    width:20px;
                    padding-bottom:.8em;
                    height:auto;
                    path{
                        fill:#adadad;
                    }
                }
                font-size:0.8em;
                padding-top:1em;
                padding-bottom:1em;
                display: block;
                padding-left:0em;
                &.active{
                   
                    background-color:#4ea7d3;
                   
                        color:white;
                   
                    svg{
                        path{
                            fill:white;
                        }

                    }
                }
            }
            li,a{
                color:#adadad;
                text-decoration: none;
            }
            
        }
       
    }
    >.right{
      
        position:absolute;
        left:100px;
        right:0px;
        
        
    }

  }
  
 
  .content
  {
      .inner{
        margin:1em;
        padding-top:1em;
        .box{
            padding:1em;
            background-color:#ffffff;
          border:1px solid lightgray;
         
            h2{
                font-size:1.3em;
                text-transform: uppercase;
                margin-top:0;
                &:after{
                    content: "";
                    display: table;
                    width:40px;
                    padding-top:5px;
                    border-bottom:2px solid #525a65;
                }
            }
        }
       .table{
           width:100%;
           border:none;
outline:none;
           th,td{
text-align:left;
padding:0;
margin:0;
border:none;
outline:none;

           }
           tbody{
            td{
              font-size:.8em;
              line-height:1.6em;
             }
             tr:nth-child(even){
  
              background-color:#f6f4f4;
             }
           }
           td,th{
             padding:.2em;
           }
           thead{
              tr.company-header{
                th{
                  font-size:1.2em;
                  line-height:2em;
                  padding-left:.5em;
                }
                background-color:#f0f0f0;
              }
              tr.field-header{
                th{
                  line-height:2em;
                }
              }
           }
           th{
               font-size:0.8em;
               text-transform: uppercase;
           }
          
          
       }
      }
    
  }


  a{
      color:black;
    text-decoration: none;
}

.form-block{
    display: flex;
    flex-wrap: wrap;
    margin-bottom:1.5em;
    h3{
        flex-basis:100%;
        font-weight: 400;
    }
    >.left{
        flex-basis:50%;
        
    }
    >.right{
        flex-basis:50%;
    }
    .input{
        padding-right:2em;
        margin-bottom:.5em;
        label{
            display: block;
            font-size:1em;
            font-weight: bold;
            font-weight: 300;
          
        }
        input{
            width:100%;
        }
    }
    .asetus{
      flex-basis: 100%;
      h5{
        text-transform: uppercase;
      }
    }
}

tr.gray{
  td{
    color:gray;
   
  }

}
.negative{


 



  td.amount{
    color:red;
    input{
      color:red;
    }
  }
}
.positive{

  td.amount{
    color:black;
    input{
      color:green;
    }
  }
}

tr.total-row 
{
  td{
 
  line-height: 3em;
  border-top:1px solid gray!important;
   font-weight: bold;
   
  }
 
  background-color: white!important;
}
.negative,.positive{

  
  td span,td div.input{
    display: inline-block;
    &.nega{
      padding-right:.2em;
    }


  }
}


.topicbar{

  display: flex;
  align-items: center;
  justify-content:flex-start; 
  .right-buttons{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

}
.sub-nav{
  span.active{
    font-weight: bold;
  }
}
.paywage{
  &.paywage-set{
    margin-top:4.5em;
  }
  h2{
    width:100%;
    margin-left:1em;
  }
  display: flex;
  flex-wrap: wrap;
  .left,.right{
   
    width:calc(50% - 4em);
    padding:2em;
  }
  .bottom{
    width:100%;
    display: flex;
    border-top:1px solid lightgray;
  }
  .buttons{
    width:100%;
    display: flex;
    border-top:1px solid lightgray;
    padding:2em;
    button{

      width:100%;
      padding-top:.5em;
      padding-bottom:.5em;
      cursor:pointer;
    }

  }
  .props{
    margin-bottom:0;
    .item-kaikki_yhteensa{
margin-top:2em;
    }
    .item-ilmoitettu_myynti,.item-provisio,.item-bonus,.item-kaikki_yhteensa,.item-kulukorvaukset_yhteensa,.item-brutto{
      span.value{
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .item{
      margin-bottom:.5em;
      display: flex;
      flex-wrap: wrap;
      span.label{
        font-weight: bold;
      
      }
      span.value{
        flex-grow: 1;
        text-align: right;
      }
      span.description{
        width:100%;
        font-style: italic;
        font-size:.9em;
      }
    }
  }
}








.month-picker {
    position: relative; }
    .month-picker > .rmp-container {
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      z-index: 100;
      top: 1px;
      left: -10000px;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container {
          position: fixed;
          top: 0;
          left: -10000px;
          width: 100%;
          height: 100%;
          transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
      .month-picker > .rmp-container.rmp-table {
        display: table; }
      .month-picker > .rmp-container.show {
        left: 0;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
        .month-picker > .rmp-container.show .rmp-overlay {
          left: 0; }
      .month-picker > .rmp-container .rmp-overlay {
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 9;
        top: 0;
        left: -10000px;
        opacity: 1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        will-change: opacity;
        transform: translateZ(0);
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-overlay {
            background-color: rgba(0, 0, 0, 0.25); } }
      .month-picker > .rmp-container .rmp-cell {
        display: table-cell;
        vertical-align: middle;
        box-sizing: border-box;
        width: 100%;
        height: 100%; }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-cell {
            vertical-align: bottom; } }
      .month-picker > .rmp-container .rmp-popup {
        position: absolute;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
        margin: 0 auto;
        z-index: 10;
        font-size: 1.2rem;
        opacity: 0;
        border-radius: 3px;
        padding: 0.4rem;
        box-sizing: content-box; }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup {
            box-sizing: border-box;
            bottom: 0;
            width: 100%;
            max-width: 100%;
            height: 14.4rem;
            transform: translate3d(0, 14.4rem, 0); }
            .month-picker > .rmp-container .rmp-popup.range {
              height: 28rem;
              transform: translate3d(0, 28rem, 0); }
              .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
                margin-top: 0.4rem; }
                .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
                  margin-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup {
            transform: translate3d(0, -64px, 0);
            top: 0;
            width: 20rem; }
            .month-picker > .rmp-container .rmp-popup.range {
              width: 40.6rem;
              padding: 0.6rem; } }
        .month-picker > .rmp-container .rmp-popup.show {
          opacity: 1;
          transform: translate3d(0, 0, 0); }
        .month-picker > .rmp-container .rmp-popup:after {
          content: ' ';
          clear: both;
          display: table; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad {
          position: relative; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad {
              box-sizing: border-box;
              float: left;
              width: 20rem; }
              .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
                float: right; } }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
            display: block;
            font-size: 1.4rem;
            text-align: center;
            line-height: 3.4rem; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
              font-weight: normal;
              margin-right: 0.5em; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
            font-style: normal;
            text-align: center;
            width: 3.4rem;
            height: 3.4rem;
            line-height: 3.4rem;
            position: absolute;
            top: 0; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
              left: 0; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
              right: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
            list-style-type: none;
            margin: 0;
            padding: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul {
            display: block;
            width: 100%; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
              content: ' ';
              clear: both;
              display: table; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad li {
            display: block;
            float: left;
            text-align: center;
            font-size: 1.15rem;
            border-radius: 3px;
            line-height: 3.3rem;
            box-sizing: border-box;
            padding: 0.05rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            *white-space: nowrap;
            overflow: hidden;
            -webkit-font-smoothing: antialiased;
            -webkit-text-stroke-width: 0.2px;
            -moz-osx-font-smoothing: grayscale;
            transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
            @media screen and (max-width: 767px) {
              .month-picker > .rmp-container .rmp-popup .rmp-pad li {
                width: 25%; } }
            @media screen and (min-width: 768px) {
              .month-picker > .rmp-container .rmp-popup .rmp-pad li {
                width: 33.3333333333%; } }
            .month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
              background-clip: content-box; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
              border-radius: 1px; }
        .month-picker > .rmp-container .rmp-popup.light {
          color: #666;
          background-color: rgba(255, 255, 255, 0.96); }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup.light {
              border-top: 1px solid #ccc;
              box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
                border-top: 1px solid rgba(204, 204, 204, 0.5); }
                .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
                  border-top: 0; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.light {
              border: 1px solid #ccc;
              box-shadow: 0 1px 5px #ddd; }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
                background-color: rgba(238, 238, 238, 0.9); } }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
            cursor: pointer;
            moz-user-select: -moz-none;
            -moz-user-select: none;
            -o-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none; }
            @media screen and (min-width: 768px) {
              .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
                background-color: rgba(255, 227, 160, 0.59); } }
            .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
              background-color: #d3d3d3; }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
            background-color: rgba(31, 42, 58, 0.73);
            color: white; }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
            background-color: transparent;
            color: #bbb;
            cursor: default; }
        .month-picker > .rmp-container .rmp-popup.dark {
          color: #fff;
          background-color: rgba(50, 50, 50, 0.96); }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
              border-top: 1px solid rgba(113, 113, 113, 0.41); }
              .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
                border-top: 0; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
              background-color: rgba(70, 70, 70, 0.9); } }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
            cursor: pointer;
            moz-user-select: -moz-none;
            -moz-user-select: none;
            -o-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none; }
            @media screen and (min-width: 768px) {
              .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
                background-color: rgba(255, 210, 96, 0.33); } }
            .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
              background-color: #262828; }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
            background-color: rgba(189, 211, 242, 0.7);
            color: #303030; }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
            background-color: transparent;
            color: #717171;
            cursor: default; }
  
  .month-picker > .box {
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .month-picker > .box {
        box-sizing: border-box;
        line-height: 5rem;
        font-size: 1.32rem;
        color: #777777;
        text-align: right; } }
    @media screen and (min-width: 768px) {
      .month-picker > .box {
        border: 1px solid #e0e0e0;
        background-color: #f6f6f6;
        padding-left: 0.9rem;
        box-sizing: border-box;
        line-height: 3.2rem;
        font-size: 1.3rem;
        color: #525252; }
        .month-picker > .box > label {
          font-size: 1.3rem;
          color: #525252; } }
  
  .month-picker .tab.btn {
    font-size: 0; }
    .month-picker .tab.btn:before {
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-family: "icomoon";
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke-width: 0.2px;
      font-size: 1.8rem;
      line-height: 3.4rem;
      text-align: center;
      width: 3.4rem; }
    .month-picker .tab.btn.prev:before {
      content: ""; }
    .month-picker .tab.btn.next:before {
      content: ""; }
  
  .years-panel {
    position: fixed;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
    .years-panel.table {
      display: table; }
    .years-panel.show {
      left: 0;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
    .years-panel.show > .overlay {
      left: 0;
      background-color: rgba(82, 82, 82, 0.49); }
    .years-panel > .overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
      top: 0;
      left: -10000px;
      opacity: 1;
      -webkit-tap-highlight-color: transparent;
      will-change: opacity;
      transform: translateZ(0);
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
    .years-panel .cell {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      box-sizing: border-box;
      width: 100%;
      height: 100%; }
    .years-panel .popup {
      display: inline-block;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0 auto;
      z-index: 10;
      font-size: 1.2rem;
      opacity: 0;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.81);
      padding: 1.8rem; }
      .years-panel .popup textarea {
        outline: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1.5rem;
        width: 20rem;
        line-height: 150%;
        padding: 1rem; }
      .years-panel .popup:after {
        content: ' ';
        clear: both;
        display: table; }
    .years-panel.show .popup {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
  
  .list-area {
    margin-top: 5rem; }
    @media screen and (min-width: 768px) {
      .list-area {
        width: 500px;
        margin-left: auto;
        margin-right: auto; } }
    @media screen and (max-width: 767px) {
      .list-area > ul {
        border-bottom: 1px solid #e8e8e8; }
        .list-area > ul > li {
          position: relative;
          background-color: #fff;
          border-top: 1px solid #e8e8e8;
          padding: 0 1.6rem; }
          .list-area > ul > li:after {
            content: ' ';
            clear: both;
            display: table; }
          .list-area > ul > li > label {
            float: left;
            display: block;
            line-height: 5rem;
            color: #440f24;
            font-size: 1.12rem; }
            .list-area > ul > li > label b {
              font-size: 1.1em; }
            .list-area > ul > li > label span {
              position: absolute;
              display: block;
              font-size: 0.8rem;
              color: #bfbfbf;
              left: 1.6rem;
              top: 1.5em; }
              .list-area > ul > li > label span i {
                display: inline-block;
                font-style: normal; }
                .list-area > ul > li > label span i.sub-btn {
                  display: none; }
          .list-area > ul > li .edit {
            margin-left: 9rem;
            line-height: 5rem; } }
    @media screen and (min-width: 768px) {
      .list-area > ul {
        padding: 1.8rem; }
        .list-area > ul > li {
          padding: 0.5rem 0 1.4rem;
          font-size: 1.3rem; }
          .list-area > ul > li:after {
            content: ' ';
            clear: both;
            display: table; }
          .list-area > ul > li > label {
            display: block;
            margin-right: 1rem;
            padding: 1px;
            color: #898989;
            font-size: 1.1rem;
            margin-bottom: 0.5em; }
            .list-area > ul > li > label b {
              display: block;
              font-size: 1.1em; }
            .list-area > ul > li > label span {
              display: block;
              font-size: 0.9em;
              color: #afafaf;
              margin-top: 0.4em; }
              .list-area > ul > li > label span i {
                display: inline-block;
                font-style: normal; }
                .list-area > ul > li > label span i.sub-btn {
                  padding: 0.1rem 0.3rem;
                  margin-left: 1.5rem;
                  border-radius: 3px;
                  background-color: #696969;
                  color: white;
                  font-size: 0.8em;
                  cursor: pointer; }
          .list-area > ul > li .edit {
            padding: 1px;
            height: 3.3rem; } }


